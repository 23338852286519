import { Injectable } from '@angular/core';
// import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs'
import { catchError, map, tap } from 'rxjs/operators';
import { Data } from '@angular/router';




interface Location {
  latitude: string;
  longitude: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private http: HttpClient) {
  }



  postMethod(url, data): Observable<Object> {

    var token = localStorage.getItem('token') !== null;
    if (token) {
      var sendToken = localStorage.getItem('token')
      data.token = sendToken;
    }
    return this.http.post(url, data).pipe(tap((res: Data) => {
      if (res.token) {
        localStorage.setItem('token', res.token);
      }
      if (res.Session == 'SessionExpired') {
        alert(res.message);
        return false;
      }
    }));
  }



  getMethod(url): Observable<Object> {
    return this.http.get(url).pipe();
    // map(response => {return response})
  }



  getLocation() {
    //  return this.http.get<Location>('http://api.ipapi.com/183.83.226.168?access_key=90c471a8143a1f696d37d5416df2e740')
    return this.http.get<Location>('http://ip-api.com/json')
    //return this.http.get<Location>('https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBpu1w_h19lX360yh3pi7fnJ9AceAhZcAQ&callback=initialize')

    //  http://api.ipapi.com/183.83.226.168?access_key=90c471a8143a1f696d37d5416df2e740
  }


}
