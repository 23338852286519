
// var baseUrl = "http://localhost:3001/";
// var baseUrl = "http://easy2learn.online:8080/";
var baseUrl = "https://easy2learn.co/";
// var baseUrl = "" ;
export const APIURL = {
  BASE_URL: baseUrl,
  REGISTRATION: baseUrl + "api/userRegistration",
  LOGIN: baseUrl + "api/login",
  GET_ALL_USER_DATA: baseUrl + "api/getUsersList",
  GET_USER_DATA: baseUrl + 'api/getUserData',
  UPDATEPAYMENT: baseUrl + "api/UpdatePayment",
  UPDATE_USER_DETAILS: baseUrl + "api/UpdateUserDetails",
  // /api/UpdateUserDetails
  DELETEUSER: baseUrl + "api/DeleteUser",
  SET_PASSWORD: baseUrl + "api/setPassword",


  UPDATE_USER_READ_QUESTIONSETS: baseUrl + "api/UpdateSubQuestionSets/Values",
  GET_USER_READ_QUESTIONSETS: baseUrl + "api/GetUserCompletedSubQuestionSets",


  GET_USERS_COUNT: baseUrl + "api/getUserCount",
  UPDATE_USER_IS_ACTIVE: baseUrl + "api/UpdateUserIsActive",

  UPDATE_PAID_CLASSES: baseUrl + "api/UpdatePaidClasses",
  REMOVE_PAID_CLASSES: baseUrl + "api/RemovePaidClasses",

  UPDATE_PERCENTAGE_FOR_USER_COMPLETED_COURSES: baseUrl + "api/UpdatePercentageForUserComletedCourses",


  //Get Users In Diferent ways
  GET_ALL_USERS_FOR_SCHOOL_ANALYTICS: baseUrl + "api/get_All_Users_for_School_Analytics",
  GET_ALL_USERS_FOR_COLLEGES: baseUrl + "api/get_All_Users_for_Colleges",

  GET_ALL_USERS_FOR_CS_DASHBOARD: baseUrl + "api/get_All_Users_for_CS_Dashboard",



  // --------------------

  INSERT_DASHBOARD: baseUrl + "api/InsertDashBoard",
  INSERT_BROWSE_CHAPTERS: baseUrl + "api/InsertBrouseChapters",
  INSERT_QUESTIONSETS: baseUrl + "api/InsertQuestionSets",
  INSERT_VIDEO_LECTURES: baseUrl + "api/InsertVideoLectures",
  INSERT_CONCEPTS: baseUrl + "api/InsertConcepts",
  INSERT_SUB_QUESTIONSETS: baseUrl + "api/InsertSubQuestionSets",
  INSERT_CHAPTER_TOPICS: baseUrl + "api/InsertChapterTopic",
  INSERT_QUIZ_DATA: baseUrl + "api/InsertQuizData",
  INSERT_ASSESSMENT_DATA: baseUrl + "api/InsertAssessments",
  INSERT_BOARDS_DATA: baseUrl + "api/InsertBoards",
  INSERT_E2LQUIZ_DATA: baseUrl + "api/InsertE2lQuizData",
  INSERT_E2LASSESSMENT_DATA: baseUrl + "api/InsertE2lAssessments",
  INSERT_MINDMAP_DATA: baseUrl + "api/InsertMindMap",
  INSERT_WORKSHEETS_DATA: baseUrl + "api/InsertWorkSheets",

  GET_DASHBOARD_DATA: baseUrl + "api/getDashboardData",
  GET_BROWSE_CHAPTERS: baseUrl + "api/getBrowseChapters",
  GET_SUBJECT_BROWSE_CHAPTERS: baseUrl + "api/getSubjectBrowseChapters",

  GET_VIDEO_LECTURES: baseUrl + "api/getVideoLectures",
  GET_CHAPTER_VIDEO_LECTURES: baseUrl + "api/getChapterVideoLectures",
  GET_CONCEPTS: baseUrl + "api/getConcepts",
  GET_QUESTIONSETS: baseUrl + "api/getQuestionSets",
  GET_SUB_QUESTIONSETS: baseUrl + "api/getSubQuestionSets",
  GET_CHAPTER_TOPICS: baseUrl + "api/getChapterTopicList",
  GET_QUIZ_DATA: baseUrl + "api/getQuizData",
  GET_ASSESSMENT_DATA: baseUrl + "api/getAssessmentData",

  GET_ASSESSMENT_DATA_For_Reports: baseUrl + "api/getAssessmentData_For_Reports",

  GET_BOARDS_DATA: baseUrl + "api/getBoards",
  GET_EXAMS_DATA: baseUrl + "api/getExams",
  GET_E2LQUIZ_DATA: baseUrl + "api/getE2lQuizData",
  GET_E2LASSESSMENT_DATA: baseUrl + "api/getE2lAssessments",
  GET_MINDMAP_DATA: baseUrl + "api/getMindMaps",
  GET_WORKSHEETS_DATA: baseUrl + "api/getWorkSheets",

  UPDATE_DASHBOARD_DATA: baseUrl + "api/UpdateDashboardData",
  DELETE_DASHBOARD_DATA: baseUrl + "api/DeleteDashboardData",

  UPDATE_BROWSE_CHAPTERS_DATA: baseUrl + "api/UpdateBrowseChaptersdData",
  DELETE_BROWSE_CHAPTERS_DATA: baseUrl + "api/DeleteBrowseChaptersData",

  UPDATE_VIDEO_LECTURES_DATA: baseUrl + "api/UpdateVideoLecturesdData",
  DELETE_VIDEO_LECTURES_DATA: baseUrl + "api/DeleteVideoLecturesdData",

  UPDATE_QUESTION_SET_DATA: baseUrl + "api/UpdateQuestionSetData",
  DELETE_QUESTION_SET_DATA: baseUrl + "api/DeleteQuestionSetData",

  UPDATE_CONCEPT_DATA: baseUrl + "api/UpdateConceptData",
  DELETE_CONCEPT_DATA: baseUrl + "api/DeleteConceptData",

  UPDATE_SUB_QUESTION_SET_DATA: baseUrl + "api/UpdateSubQuestionSetData",
  DELETE_SUB_QUESTION_SET_DATA: baseUrl + "api/DeleteSubQuestionSetData",

  UPDATE_CHAPTER_TOPICS_DATA: baseUrl + "api/UpdateChapterTopicsData",
  DELETE_CHAPTER_TOPICS_DATA: baseUrl + "api/DeleteChapterTopicsData",

  UPDATE_QUIZ_DATA: baseUrl + "api/UpdateQuizData",
  DELETE_QUIZ_DATA: baseUrl + "api/DeleteQuizData",

  UPDATE_ASSESSMENTS: baseUrl + "api/UpdateAssessments",
  DELETE_ASSESSMENTS: baseUrl + "api/DeleteAssessments",

  UPDATE_BOARDS: baseUrl + "api/UpdateBoards",
  DELETE_BOARDS: baseUrl + "api/DeleteBoards",

  UPDATE_E2LQUIZ_DATA: baseUrl + "api/UpdateE2lQuizData",
  DELETE_E2LQUIZ_DATA: baseUrl + "api/DeleteE2lQuizData",

  UPDATE_E2LASSESSMENTS: baseUrl + "api/UpdateE2lAssessments",
  DELETE_E2LASSESSMENTS: baseUrl + "api/DeleteE2lAssessments",

  UPDATE_MINDMAPS: baseUrl + "api/UpdateMindMap",
  DELETE_MINDMAPS: baseUrl + "api/DeleteMindMap",

  UPDATE_WORKSHEETS: baseUrl + "api/UpdateWorkSheets",
  DELETE_WORKSHEETS: baseUrl + "api/DeleteWorkSheets",

  /*=====================ADMIN LOGIN  ===================*/

  ADMIN_LOGIN: baseUrl + "api/AdminLogin",

  DIRECT_ADMIN_LOGIN: baseUrl + "api/DirectAdminLogin",

  ADMIN_REGISTRATION: baseUrl + "api/AdminRegistration",
  GET_ADMIN_INFO: baseUrl + 'api/getAdminInfo',
  DELETE_ADMIN_INFO: baseUrl + 'api/DeleteAdminRecord',

  //cs admin
  CS_ADMIN_LOGIN: baseUrl + "api/CsAdminLogin",
  CS_ADMIN_REGISTRATION: baseUrl + "api/CsAdminRegistration",
  GET_CS_ADMIN_INFO: baseUrl + 'api/getCsAdminInfo',
  DELETE_CS_ADMIN_INFO: baseUrl + 'api/DeleteCsAdminRecord',



  INSERT_NUMBER_OF_VISITED_USERS: baseUrl + 'api/InsertNumberOfVisitedUsers',
  GET_VISITED_USERS: baseUrl + 'api/GetVisitedUsers',
  DELETE_BEFORE_VISITED_USERS: baseUrl + 'api/DeleteBeforeVisitedUsers',

  INSERT_NUMBER_OF_VISITED_COLLEGE_USERS: baseUrl + 'api/InsertNumberOfVisitedCollegeUsers',
  GET_VISITED_COLLEGE_USERS: baseUrl + 'api/getVisitedCollegeUsers',
  DELETE_BEFORE_VISITED_COLLEGE_USERS: baseUrl + 'api/DeleteBeforeVisitedCollegeUsers',





  /*=====================ADMIN LOGIN  ===================*/



  /*=====================sms otp  ==================== */
  // SEND_OTP : baseUrl + 'api/sendOTP'

  USER_REGISTRATION_WITH_OTP: baseUrl + 'api/userRegisterWithOTP',
  VERIFY_OTP: baseUrl + 'api/VerifyOTP',
  RESEND_OTP: baseUrl + 'api/resendOTP',
  LOGIN_VERIFY_OTP: baseUrl + 'api/checkUser',
  VERIFY_FORGOT_PASSWORD_OTP: baseUrl + 'api/VerifyForgotPasswordOTP',


  GET_USERS_WITH_OTP: baseUrl + 'api/GetUsersWithOtp',
  DELETE_USERS_WITH_OTP: baseUrl + 'api/DeleteUserWithOtp',


  /*====================== bookmark ==============*/

  ADD_BOOKMARK: baseUrl + 'api/Concept/Bookmark',
  GET_BOOKMARKS_LIST: baseUrl + 'api/GetBookmarksList',
  REMOVE_BOOKMARK: baseUrl + 'api/Concept/Bookmark/delete',

  ADD_NOTEBOOK_NAME: baseUrl + 'api/NoteBookName',
  GET_NOTEBOOK_NAMES: baseUrl + 'api/GetNoteBookName',
  /*====================== bookmark ==============*/




  /*====================== Assessments ==============*/
  SAVE_ASSESSMENT_REPORTS: baseUrl + 'api/AssessmentReport',
  CHECK_ASSESSMENT_REPORTS: baseUrl + 'api/CheckAssessmentExist',
  GET_ASSESSMENT_REPORTS: baseUrl + 'api/GetAssessmentReportList',

  /*====================== Assessments ==============*/


  /*====================== E2lAssessments ==============*/
  SAVE_E2lASSESSMENT_REPORTS: baseUrl + 'api/SaveE2lAssessmentReports',
  CHECK_E2lASSESSMENT_REPORTS: baseUrl + 'api/CheckE2lAssessmentExist',
  GET_E2lASSESSMENT_REPORTS: baseUrl + 'api/GetE2lAssessmentReportList',

  /*====================== E2lAssessments ==============*/


  /*====================== School Admin start ==============*/
  SCHOOL_ADMIN_REGISTER: baseUrl + 'api/School/AdminRegistration',
  SCHOOL_ADMIN_LOGIN: baseUrl + 'api/School/AdminLogin',
  GET_ALL_SCHOOL_RECORDS: baseUrl + 'api/School/getSchoolsList',
  DELETE_SCHOOL_RECORDS: baseUrl + 'api/School/DeleteSchoolRecord',

  UPDATE_ADD_SCHOOL_CLASSES: baseUrl + 'api/School/UpdateAddSchoolClasses',
  UPDATE_REMOVE_SCHOOL_CLASSES: baseUrl + 'api/School/UpdateRemoveSchoolClasses',
  UPDATE_SCHOOL_RECORDS: baseUrl + 'api/School/UpdateSchoolData',

  SCHOOL_FACULTY_REGISTER: baseUrl + 'api/School/RegisterFaculty',
  SCHOOL_FACULTY_LOGIN: baseUrl + 'api/School/LoginFaculty',
  GET_ALL_SCHOOL_FACULTY_RECORDS: baseUrl + 'api/School/getFacultyList',
  DELETE_SCHOOL_FACULTY_RECORDS: baseUrl + 'api/School/DeleteFacultyRecord',
  BULK_OF_FACULTY_REGISTRATION: baseUrl + 'api/bulkFacultyRegistration',

  SCHOOL_STUDENT_REGISTRATION: baseUrl + 'api/School/StudentRegistration',
  GET_SCHOOL_STUDENTS_DATA: baseUrl + 'api/School/getSchoolStudentsList',
  DELETE_SCHOOL_STUDENT_RECORDS: baseUrl + 'api/School/DeleteSchoolStudentRecord',
  BULK_OF_STUDENTS_REGISTRATION: baseUrl + 'api/bulkStudentRegistration',

  UPDATE_STUDENT_CLASS: baseUrl + 'api/School/UpdateStudentClass',
  DELETE_STUDENT_CLASS: baseUrl + 'api/School/DeleteStudentClass',

  BULK_QUIZ_INSERTIONS: baseUrl + 'api/bulkQuizInsertions',

  BULK_E2LQUIZ_INSERTIONS: baseUrl + 'api/bulkE2lQuizInsertions',

  BULK_OF_CHAPTERS_INSERTION: baseUrl + 'api/bulkChapterInsertion',
  BULK_OF_SUBQUESTIONSETS_INSERTION: baseUrl + 'api/bulkSubQuestionsetsInsertion',

  GET_STUDENTS_WITH_PERCENTAGE: baseUrl + 'api/getAllUsersWithPercentage',


  INSERT_SCHOOL_DEPARTMENT_FACULTY: baseUrl + 'api/insertSchoolDepartmentFaculty',
  GET_SCHOOL_DEPARTMENT_FACULTY: baseUrl + 'api/getSchoolDepartmentFaculty',
  DELETE_SCHOOL_DEPARTMENT_FACULTY: baseUrl + 'api/DeleteSchoolDepartmentFaculty',

  UPDATE_FACULTY_STATUS: baseUrl + 'api/UpdateFacultyStatus',
  UPDATE_FACULTY_PASSWORD: baseUrl + 'api/UpdateFacultyPassword',
  UPDATE_SCHOOL_PASSWORD: baseUrl + 'api/UpdateSchoolPassword',

  UPDATE_SCHOOL_FACULTY_STUDENTS_ACTIVE: baseUrl + 'api/UpdateSchoolFacultyStudentsActive',



  INSERT_HOD_DATA: baseUrl + 'api/School/HodRegistration',
  HOD_LOGIN: baseUrl + 'api/School/HodLogin',
  GET_HOD_LIST: baseUrl + 'api/School/getHodList',
  DELETE_HOD_RECORD: baseUrl + 'api/School/DeleteHodRecord',
  UPDATE_HOD_DATA: baseUrl + 'api/School/UpdateHodData',

  /*====================== School Admin end==============*/


  /* WMWMWMWMWWMWMWMWMWWMWMW   New   MWMWWMWMWMWMWWMWMWMWMWWMWMWMWMW */

  INSERT_CODE_PRACTICE: baseUrl + 'api/InsertCodePractice',
  GET_CODE_PRACTICE: baseUrl + 'api/getCodePracticeContent',
  UPDATE_CODE_PRACTICE: baseUrl + 'api/UpdateCodePractice',
  DELETE_CODE_PRACTICE: baseUrl + 'api/DeleteCodePractice',

  INSERT_CODE_TEST: baseUrl + 'api/CodeTest',
  GET_CODE_TEST: baseUrl + 'api/getCodeTestData',
  UPDATE_CODE_TEST: baseUrl + 'api/UpdateCodeTest',
  DELETE_CODE_TEST: baseUrl + 'api/DeleteCodeTest',

  SAVE_CODETEST_REPORTS: baseUrl + 'api/SaveCodeTestReports',
  CODE_TEST_REPORTS_UPDATE: baseUrl + 'api/CodeTestReportsUpdate',
  GET_CODE_TEST_REPORTS: baseUrl + 'api/GetCodeTestReportList',

  INSERT_CODE_ALIGNMENT: baseUrl + 'api/InsertCodeAlignment',
  GET_CODE_ALIGNMENT: baseUrl + 'api/getCodeAlignmentContent',
  UPDATE_CODE_ALIGNMENT: baseUrl + 'api/UpdateCodeAlignment',
  DELETE_CODE_ALIGNMENT: baseUrl + 'api/DeleteCodeAlignment',

  INSERT_CODE_INSPECT: baseUrl + 'api/CodeInspect',
  GET_CODE_INSPECT: baseUrl + 'api/getCodeInspectData',
  UPDATE_CODE_INSPECT: baseUrl + 'api/UpdateCodeInspect',
  DELETE_CODE_INSPECT: baseUrl + 'api/DeleteCodeInspect',



  //  profile.....
  UPDATE_PROFILE: baseUrl + 'api/UpdateProfile',


  // careers
  CAREER_REGISTRATION: baseUrl + 'api/CareerRegistertion',
  GET_CAREER_INFO: baseUrl + 'api/getCareerInfo',


  // number Of page Views
  GET_NUMBER_OF_PAGE_VIEWS: baseUrl + 'api/NumberOfPageViews',


  /*============== Doubts Admin ================*/

  DOUBTS_ADMIN_FACULTY_REGISTER: baseUrl + 'api/DoubtsAdminFacultyRegister',
  DOUBTS_ADMIN_FACULTY_lOGIN: baseUrl + 'api/DoubtsAdminFacultyLogin',
  GET_DOUBTS_ADMIN_FACULTY_INFO: baseUrl + 'api/getDoubtsAdminFacultyInfo',
  DELETE_DOUBTS_ADMIN_FACULTY_RECORD: baseUrl + 'api/DeleteDountsAdminFacultyRecord',

  /*============== Doubts Admin ================*/




  /*============== payment gateway start ================*/
  MAKE_PAYMENT: baseUrl + 'api/makePayment',
  INSERT_PAYMENT_DETAILS: baseUrl + 'api/InsertPaymentDetails',
  GET_PAID_COURSES: baseUrl + 'api/getPaidCourses',
  INSERT_AND_UPDATE_GST: baseUrl + 'api/insertGSTAndUpdate',
  GET_GST_RATE: baseUrl + 'api/getGSTRate',

  GET_PAID_USERS: baseUrl + 'api/getPaidUsers',

  GET_BEFORE_PAID_PAYMENT_DETAILS: baseUrl + 'api/getBeforePaidPaymentDetails',
  DELETE_BEFORE_PAID_PAYMENT_DETAILS: baseUrl + 'api/DeleteBeforePaidPaymentDetails',
  /*============== payment gateway end ================*/



  /*============== courses start ================*/
  INSERT_COURSES: baseUrl + 'api/InsertCourses',
  ASSIGN_COURSES: baseUrl + 'api/AssignCourse',
  GET_ALL_COURSES: baseUrl + 'api/getAllCourses',
  UPDATE_COURSE: baseUrl + 'api/UpdateCourse',
  DELETE_COURSE: baseUrl + 'api/DeleteCourse',
  /*============== courses end ================*/



  /*=============== chatting start =============*/

  SEND_MESSAGE: baseUrl + 'api/SendMessage',
  GET_MESSAGES: baseUrl + 'api/getMessages',
  UPDATE_READ_MESSAGES: baseUrl + 'api/UpdateReadMessage',
  GET_ALL_CHATTING_HISTORY: baseUrl + 'api/GetAllChattingHistory',


  LOGOUT_USER_STATUS: baseUrl + 'api/Logout/UpdateUserStatus',
  LOGIN_USER_STATUS: baseUrl + 'api/Login/UpdateUserStatus',
  /*=============== chatting end ==============*/


  /* =====================Referring Start=============== */
  SEND_REFERRAL_SMS: baseUrl + 'api/Referring/SendReferralSMS',
  SEND_REFERRAL_MAIL: baseUrl + 'api/Referring/SendReferralMail',
  GET_REFERRED_USERS: baseUrl + 'api/getReferredUsers',
  UPDATE_REFERRAL_PAYMENT: baseUrl + 'api/updateReferralPayment',
  GET_REFERAL_DETAILS: baseUrl + 'api/Referring/getReferralDetails',
  CREATE_MY_REFERRALID: baseUrl + 'api/CreateMyReferralId',
  CHECK_REFERRAL_ID_ISACTIVE: baseUrl + 'api/CheckReferralIdIsActive',
  GET_REFERRED_PAID_USERS: baseUrl + 'api/getReferredPaidUsers',

  /* =====================Referring End=============== */



  /* =====================Invitation start=============== */

  SEND_INVITATION_MAIL: baseUrl + 'api/SendInvitationMail',

  /* =====================Invitation end=============== */




  /* WMWMWMWMWWMWMWMWMWWMWMW  New    MWMWWMWMWMWMWWMWMWMWMWWMWMWMWMW */

  MULTIPLE_QUIZ_RECORDS_INSERTION: baseUrl + 'api/multipleQuizInsertion',



  ////////////////////BULK SMS//////////////////////////
  SEND_SMS_FOR_USERS: baseUrl + 'api/SendSMSForUsers',
  SEND_EMAIL_FOR_USERS: baseUrl + 'api/SendMailsForUsers',


  //Outside Users...
  INSERT_OUTSIDE_USERS: baseUrl + 'api/InsertOutsideUsers',
  INSERT_MULTIPLE_OUTSIDE_USERS: baseUrl + 'api/InsertMuntipleOutsideUsers',
  GET_OUTSIDE_USERS: baseUrl + 'api/GetOutsideUsers',
  GET_OUTSIDE_USERS_LABELS: baseUrl + 'api/GetOutsideUserLables',
  DELETE_OUTSIDE_USER: baseUrl + 'api/DeleteOutsideUser',






  ///////////////////////Easy2learnBrandAmbassadorRegistrations///////////////////////////

  EASY2LEARN_BRAND_AMBASSADOR_INSERTION: baseUrl + 'api/Easy2learnBrandAmbassadorInsertion',
  GET_EASY2LEARN_BRAND_AMBASSADOR_DETAILS: baseUrl + 'api/GetEasy2learnBrandAmbassadorDetails',
  UPDATE_EASY2LEARN_BRAND_AMBASSADOR_DETAILS: baseUrl + 'api/UpdateEasy2learnBrandAmbassadorDetails',
  DELETE_EASY2LEARN_BRAND_AMBASSADOR_DETAILS: baseUrl + 'api/DeleteEasy2learnBrandAmbassadorDetails',



  /////////////////enquiry form /////////////////////////////
  ENQUIRY_INSERTION: baseUrl + "api/Enquiry/Insertion",
  GET_ENQUIRY_DETAILS: baseUrl + "api/Enquiry/GetAllEnquiryDetails",
  DELETE_ENQUIRY_RECORD: baseUrl + "api/Enquiry/DeleteRecord",


  // admission users
  CREATE_STUDENT_ADMISSION: baseUrl + "api/CreateStudentAdmission",
  GET_ALL_STUDENT_ADMISSIONS: baseUrl + "api/GetAllStudentAdmission",
  UPDATE_STUDENT_ADMISSION: baseUrl + "api/UpdateStudentAdmission",
  DELETE_STUDENT_ADMISSION: baseUrl + "api/DeleteStudentAdmission",
  UPDATE_STUDENT_ADMISSION_IMAGE: baseUrl + "api/UpdateAdmissionUserImage",
  GET_STUDENT_ADMISSION_SINGLE_RECORD: baseUrl + "api/getAdmissionSingleRecord",



  // workshop
  CREATE_WORKSHOP: baseUrl + "api/InsertWorkshop",
  GET_WORKSHOP_TITLES: baseUrl + "api/GetWorkshopTitles",
  UPDATE_WORKSHOP: baseUrl + "api/UpdateWorkshop",
  DELETE_WORKSHOP_TITLE: baseUrl + "api/DeleteWorkshop",
  ACCEPT_WORKSHOP_TITLE: baseUrl + "api/AcceptWorkshop",


  INSERT_WORKSHOP_FIELD: baseUrl + "api/InsertWorkshopfield",
  GET_WORKSHOP_FIELDS: baseUrl + "api/GetWorkshopFields",
  UPDATE_WORKSHOP_FIELD: baseUrl + "api/UpdateWorkshopFields",
  DELETE_WORKSHOP_FIELD: baseUrl + "api/DeleteWorkshopFields",

  INSERT_WORKSHOP_DETAILS: baseUrl + "api/WorkshopsDetailsInsertion",
  GET_WORKSHOP_DETAILS: baseUrl + "api/GetWorkshopsDetails",


  // ASSIGNMENTS 
  ASSIGNMENT_INSERTION: baseUrl + "api/Assignments/Insertion",
  GET_ALL_ASSIGNMENTS: baseUrl + "api/Assignments/GetAssignments",
  UPDATE_ASSIGNMENT_DATA: baseUrl + "api/Assignments/UpdateAssignmentsData",
  DELETE_ASSIGNMENT: baseUrl + "api/Assignments/DeleteRecord",

  // SUBMITED ASSIGNMENTS
  SUBMITED_ASSIGNMENT_INSERTION: baseUrl + "api/SubmitedAssignments/Insertion",
  GET_SUBMITED_ASSIGNMENTS: baseUrl + "api/SubmitedAssignments/getSubmitedAssignments",
  UPDATE_SUBMITED_ASSIGNMENTS_MARKS: baseUrl + "api/SubmitedAssignments/UpdateSubmitedAssignmentsMarks",



}