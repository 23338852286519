
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";

import { AgmCoreModule } from '@agm/core';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { DragulaService } from 'ng2-dragula';
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import * as $ from 'jquery';
import { ToastrModule } from 'ngx-toastr';

import { CKEditorModule } from 'ng2-ckeditor';

import { environment } from 'environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';


import { ChatService } from './services/chat.service';
import { CommonService } from './common.service'
import { MainMenuDirective } from './main-menu.directive';
import { ExcelService} from './excel.service'

// import { Safehtml3Pipe } from './safehtml3.pipe';

// import { LocationStrategy, HashLocationStrategy } from '@angular/common';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        MainMenuDirective,
        // Safehtml3Pipe,

    ],
    imports: [
        BrowserAnimationsModule,
        StoreModule.forRoot({}),
        AppRoutingModule,    // Routings ( ctrl + click );
        SharedModule,
        SnotifyModule.forRoot(),
        HttpClientModule,
        NgbModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 4000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            enableHtml: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBpu1w_h19lX360yh3pi7fnJ9AceAhZcAQ',
            libraries: ["places"]
        }),

        AngularFireModule.initializeApp(environment.firebase, 'easy2learn chat application'),
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        AngularFireStorageModule, // imports firebase/storage only needed for storage features
        AngularFireDatabaseModule,
        CKEditorModule

    ],
    providers: [
        { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
        SnotifyService,
        AuthService,
        AuthGuard,
        DragulaService,
        CommonService,
        ExcelService,
        ChatService,


        // { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }