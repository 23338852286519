import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [

    {
        path: '',
        loadChildren: './Home/home-page.module#HomePageModule'
    },
    // {
    //     path: 'Home',
    //     loadChildren: './Home/home-page.module#HomePageModule'
    // },
    {
        path: 'registration',
        loadChildren: './registrationPages/register-pages.module#RegisterPageModule'
    },

    {
        path: 'Admin',
        loadChildren: './Admin/admin.module#AdminModule'
    },
  
   

    
];
