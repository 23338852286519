import { Component, OnInit, HostListener } from '@angular/core';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute, Data } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { APIURL } from 'app/url';
import { CommonService } from 'app/common.service'
declare var $: any;
import { ChatService } from '../../services/chat.service'
// import { copyStyles } from '@angular/animations/browser/src/util';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    LoginData;

    constructor(private router: Router,
        private route: ActivatedRoute, public translate: TranslateService, public commonService: CommonService, private chat: ChatService,) {
        // this.LoginData = JSON.parse(localStorage.getItem('LoginUser'));
        var Login_User = localStorage.getItem('LoginUser');
        if (Login_User) {
            this.LoginData = JSON.parse(Login_User);
        }
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');

        this.menuItems = [
            // Starting Page start
            { path: '/learn/mainPage', title: 'Dashboard', icon: '../assets/Icons/default.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/learn/LeaderBoard', title: 'Leader Board', icon: '../assets/Icons/statistics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/learn/bookmarks', title: 'Bookmarks', icon: '../assets/Icons/bookmark2.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

            {
                path: '', title: 'Tests', icon: '../assets/Icons/test.png', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    { path: '/learn/E2lAssessment', title: 'E2l Assessments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/learn/assessment', title: 'College Assessments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/learn/CodeTest', title: 'College Code Test', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/learn/Assignments', title: 'Assignments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

                ]
            },

            {
                path: '', title: 'Reports', icon: '../assets/Icons/pie-chart.png', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    { path: '/learn/E2lQuizReports', title: 'E2l Quiz-Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/learn/quizReport', title: 'Quiz Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/learn/CodeTestReports', title: 'Code Test Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/learn/AssignmentReports', title: 'Assignment Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

                ]
            },
            // { path: '/learn/E2lAssessment', title: 'E2l Assessments', icon: '../assets/Icons/test.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/learn/E2lQuizReports', title: 'E2l Quiz-Reports', icon: '../assets/Icons/e2lChart.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/learn/assessment', title: 'College Assessments', icon: '../assets/Icons/test1.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/learn/quizReport', title: 'Quiz Reports', icon: '../assets/Icons/pie-chart.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/learn/CodeTest', title: 'College Code Test', icon: '../assets/Icons/code.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/learn/CodeTestReports', title: 'Code Test Reports', icon: '../assets/Icons/codetestreport.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/learn/doubts', title: 'Doubts', icon: '../assets/Icons/doubts.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/learn/ReferAndEarn', title: 'Refer & Earn', icon: '../assets/Icons/contact.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]

        this.LoginData.Courses.forEach((course, index) => {
            this.menuItems.splice(index + 1, 0, { path: '/learn/BrowseChapters/' + course.CourseName, title: course.CourseName, icon: course.Icon, class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] });
        });


        this.visitUsersCount();
        this.deleteVisitedCollegeUsers();
    }


    deleteVisitedCollegeUsers() {
        var url = APIURL.DELETE_BEFORE_VISITED_COLLEGE_USERS;
        this.commonService.postMethod(url, {})
            .subscribe((data: Data) => {
                this.DeletevisitUsers();
            })
    }

    DeletevisitUsers() {
        var url = APIURL.DELETE_BEFORE_VISITED_USERS;
        this.commonService.postMethod(url, {})
            .subscribe((data: Data) => {
            })
    }


    visitUsersCount() {
        var url = APIURL.INSERT_NUMBER_OF_VISITED_USERS;
        this.commonService.postMethod(url, {})
            .subscribe((data: Data) => {
                this.visitUsersCollegeCount();
            })
    }

    visitUsersCollegeCount() {
        if (this.LoginData.SchoolName) {
            var obj = {
                MobileNumber: this.LoginData.MobileNumber,
                SchoolName: this.LoginData.SchoolName,
                FacultyID: this.LoginData.FacultyID
            }
            var url = APIURL.INSERT_NUMBER_OF_VISITED_COLLEGE_USERS;
            this.commonService.postMethod(url, obj)
                .subscribe((data: Data) => {
                })
        }
    }



}
