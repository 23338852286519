import { Component, ViewContainerRef } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Data, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  // loginData;
  constructor(
    private router: Router,
    private route: ActivatedRoute) {

    // this.loginData = JSON.parse(localStorage.getItem('LoginUser'));

    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )

    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-134899784-1', {
        'page_path': event.urlAfterRedirects,
        // 'mobileNumber' : this.loginData.mobileNumber
      });
    });
  }


}